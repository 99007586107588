(function() {
  var Utilities = (function() {
    return {
      init: function() {
        function openLSHDWindow(
          year,
          wkno,
          eventid,
          tour,
          lang,
          ref_file,
          width,
          height,
          hasScrollBars
        ) {
          var theWidth = width;

          var theHeight = height;

          var scrollBars = "scrollbars";

          if (hasScrollBars == false) scrollBars = "scrollbars=0";

          if (theWidth == "" || theWidth == null) theWidth = 1042;

          if (theHeight == "" || theHeight == null) theHeight = 631;

          var theLeft = (screen.availWidth - theWidth) / 2;

          var theTop = (screen.availHeight - theHeight) / 2;

          var strCheckRef = escape(ref_file);

          var lsURL =
            "http://www.protennislive.com/lshd/main.html?year=" +
            year +
            "&wkno=" +
            wkno +
            "&eventid=" +
            eventid +
            "&tour=" +
            tour +
            "&lang=" +
            lang +
            "&ref=" +
            strCheckRef;

          window.open(
            lsURL,
            "_" + Math.round(Math.random() * 1000000),
            "top=" +
              theTop +
              ",left=" +
              theLeft +
              ",menubar=0,toolbar=0,location=0,directories=0,status=0," +
              scrollBars +
              ",width=" +
              theWidth +
              ", height=" +
              theHeight
          );
        }

        $(".js-live-scores").on("click", function(event) {
          event.preventDefault();
          openLSHDWindow(
            2020,
            "30",
            "0221",
            "1",
            "en",
            "https://www.tampereopen.fi"
          );
        });
      }
    };
  })();

  const MobileMenu = (function() {
    return {
      init: function() {
        document
          .querySelector(".Header__mobile-hamburger-link")
          .addEventListener("click", function(e) {
            document
              .querySelector(".Header__mobile")
              .classList.toggle("Header__mobile--active");
          });
      }
    };
  })();

  // const CountdownTimer = (function() {
  //   return {
  //     init: function() {
  //       const date = new Date("2023-07-17").getTime();
  //       const now = new Date().getTime();
  //       const distance = date - now;
  //       const days = Math.floor(distance / (1000 * 60 * 60 * 24));

  //       if (distance > 0) {
  //         window.addEventListener("DOMContentLoaded", () => {
  //           document
  //             .querySelector("#countdown")
  //             .innerHTML = `<span>Turnaus alkaa ${days} päivän päästä!</span>`;
  //         });
  //       }
  //     }
  //   };
  // })();

  MobileMenu.init();
})();
