(() => {
  const changeBgImage = () => {
    const page = document.querySelector(".Page");

    if (
      window.innerWidth < 768 &&
      page.classList.value.indexOf("Page--frontpage") === -1
    ) {
      return;
    }

    const imageLocation = page.dataset.location;
    if (!imageLocation) {
      return;
    }
    const image = new Image();
    image.onload = () => {
      page.style.backgroundImage = `url('${image.src}')`;
    };
    image.src = imageLocation + Math.floor(Math.random() * 6 + 1) + ".jpg";
  };
  changeBgImage();
  setInterval(changeBgImage, 8000);
})();
